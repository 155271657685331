import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { useTranslation } from '../../i18n/translator';

import PhoneIcon from '../assets/phone.svg';
import MailIcon from '../assets/mail.png';
import { handlePhoneClick } from '../../helpers';
import './styles.css';

interface Icontact {
  phones: string[];
  emails: string[];
  title: string;
}

const contacts: Icontact[] = [
  {
    title: 'customs_clearance',
    phones: ['(067) 149 81 99', '(067) 149 81 14'],
    emails: ['broker.gorodok@gmail.com', 'resursub@ukr.net'],
  },
  {
    title: 'Бухгалтерія',
    phones: ['(032) 244 44 23', '(032) 244 83 83', '(066) 858 99 42'],
    emails: ['buh.zmk@gmail.com'],
  },
  {
    title: 'mutne_oformlenya_tz',
    phones: ['(067) 111 46 44'],
    emails: ['zravto@gmail.com'],
  },
  {
    title: 'temporary_storage',
    phones: ['(097) 114 93 49'],
    emails: [],
  },

  {
    title: 'oformlenya_ugod',
    phones: ['(068) 708 30 88'],
    emails: [],
  },
];

const Contacts = () => {
  return (
    <Col
      md={10}
      sm={12}
      className='mb-5 py-3'
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      id='contacts'
    >
      <Row className='contacts-row'>
        {contacts.map((item, index) => (
          <ContactsCard item={item} key={index} />
        ))}
      </Row>
    </Col>
  );
};

export default Contacts;

const ContactsCard = ({ item }: { item: Icontact }) => {
  const { t } = useTranslation();
  return (
    <Col
      className='contacts-col mb-3'
      style={{
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: 20,
        justifyContent: 'center',
      }}
    >
      <Card.Body className='contacts-card'>
        <Card.Title style={{ fontSize: 20, marginBottom: 10 }}>
          {t(`translate:${item.title}`)}
        </Card.Title>
        {item.phones.map((p, index) => (
          <Card.Text
            key={index}
            className='phone-number'
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => handlePhoneClick(p)}
          >
            <img src={PhoneIcon} alt='Phone Icon' height={16} width={16} />
            <span>{p}</span>
          </Card.Text>
        ))}
        {item.emails.map((e, index) => (
          <Card.Text
            key={index}
            style={{ gap: 10, display: 'flex', alignItems: 'center' }}
            onClick={() => handlePhoneClick(e)}
          >
            <img src={MailIcon} alt='Mail Icon' height={16} width={16} />
            <span>{e}</span>
          </Card.Text>
        ))}
      </Card.Body>
    </Col>
  );
};
