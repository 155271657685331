export const customAlt = `Митниця
 Митниця львів
 Логістика львів
 Оренда комерція
 Оренда складів
 Склади львів
 Транспортні перевезення
 Перевезення
 Захід ресурс
 Городок 
 Митний брокер 
 Брокер митниця
 Митний брокер львів
 Розмитнення авто
 Розмитнення львів
 Оренда офісу львів
 Оренда офісу городок
 Оренда офісу 
 Оренда складських приміщень
 Оренда техніки львів
 Оренда спецтехніки львів
 Митне оформлення вантажів
 Оренда погрузчика 
 Оренда навантажувача
 митниця
митниця львів
логістика львів
оренда комерція
оренда складів
склади львів
транспортні перевезення
перевезення
захід ресурс
городок
митниці
логіст львів
логістика робота львів
робота логістика львів
робота львів логістика
транспортні перевезення це
`;

export const scrollToElement = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    const headerOffset = 150;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    () => {
      alert(`Скопійовано: ${text}`);
    },
    (err) => console.error('Копіювання не вдалось: ', err)
  );
};

// Функція для визначення, чи є пристрій мобільним
const isMobileDevice = () => {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
};

export const handlePhoneClick = (phone: string) => {
  if (isMobileDevice()) {
    window.location.href = `tel:${phone}`;
  } else {
    copyToClipboard(phone);
  }
};

export const stripHTMLTags = (html: string | any): string => {
  return html.replace(/<\/?[^>]+(>|$)/g, '');
};
export function splitArray<T>(arr: T[]): { evenIndices: T[]; oddIndices: T[] } {
  const evenIndices: T[] = [];
  const oddIndices: T[] = [];

  for (let i = 0; i < arr.length; i++) {
    if (i % 2 === 0) {
      evenIndices.push(arr[i]);
    } else {
      oddIndices.push(arr[i]);
    }
  }

  return { evenIndices, oddIndices };
}
export const debounce = (
  func: { apply: (arg0: null, arg1: any[]) => void },
  delay: number | undefined
) => {
  let timeoutId: string | number | NodeJS.Timeout | undefined;
  let calledOnce = false;

  return (...args: any) => {
    if (!calledOnce) {
      func.apply(null, args);
      calledOnce = true;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func.apply(null, args);
      calledOnce = false;
    }, delay);
  };
};
