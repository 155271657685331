import React, { useCallback, useState } from 'react';
import StyledInput from '../styledCompornents/input';
import StyledButton from '../styledCompornents/button';
import ElementSlideAnimation from '../animations/ElementSlideAnimation';
import CompletedAnimation from '../animations/CompletedAnimation';
import { submitForm } from '../../thunk/sendFormData';
import { useTranslation } from '../../i18n/translator';
import { stripHTMLTags } from '../../helpers';

const ConsultForm = () => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [animationStarted, setAnimationStarted] = useState(false);
  const sendForm = useCallback(() => {
    if (!phone || !name) {
      return;
    }
    submitForm({
      contact: phone,
      name: name,
    });
    setAnimationStarted(true);
    setTimeout(() => {
      setAnimationStarted(false);
      setPhone('');
      setName('');
    }, 1200);
  }, [phone, name]);

  return (
    <div
      style={{
        maxWidth: 1350,
        backgroundColor: '#FAFAFB',
        borderRadius: 15,
        marginTop: '5vh',
        paddingTop: 40,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 20,
      }}
    >
      <h1
        style={{
          lineHeight: '63px',
          textAlign: 'center',
        }}
      >
        {t('translate:order_consultation')}
      </h1>

      <h2
        style={{
          textAlign: 'center',
        }}
      >
        {t('translate:call_now')}
      </h2>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: 20,
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            gap: 20,
          }}
        >
          <div
            style={{
              width: 300,
              height: 59,
              position: 'relative',
              overflow: 'hidden',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              borderRadius: 15,
            }}
          >
            <StyledInput
              id='formNameInput'
              style={{ width: 300, height: 59, maxWidth: '90%' }}
              value={name}
              onChange={setName}
              placeholder={stripHTMLTags(
                t('translate:name', { onlyText: true })
              )}
            />
            <ElementSlideAnimation animationStarted={animationStarted} />
          </div>
          <div
            style={{
              width: 300,
              height: 59,
              position: 'relative',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              borderRadius: 15,
            }}
          >
            <StyledInput
              id={'formPhoneInput'}
              value={phone}
              onChange={setPhone}
              style={{ width: 300, maxWidth: '90%', height: 59 }}
              placeholder={stripHTMLTags(
                t('translate:phone_number', { onlyText: true })
              )}
            />
            <ElementSlideAnimation animationStarted={animationStarted} />
          </div>
        </div>
        <StyledButton
          action={sendForm}
          text={stripHTMLTags(t('translate:send', { onlyText: true }))}
          style={{ width: 219, height: 59 }}
        >
          <CompletedAnimation animationStarted={animationStarted} />
        </StyledButton>
      </div>
    </div>
  );
};

export default ConsultForm;
