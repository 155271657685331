import React from 'react';
import DOMPurify from 'dompurify';
import { useTranslation as useReactI18NextTranslation } from 'react-i18next';
import { stripHTMLTags } from '../../src/helpers';

// Обгортка над useTranslation
export const useTranslation = () => {
  const { t: originalT, ...rest } = useReactI18NextTranslation();

  // Нова функція t, яка викликає DOMPurify після перекладу
  const t = (key: string | string[], options?: { onlyText?: boolean }) => {
    const translation = originalT(key, options); // отримуємо переклад

    const cleanHTML = DOMPurify.sanitize(translation as string); // очищаємо HTML
    if (options?.onlyText) {
      return stripHTMLTags(cleanHTML);
    }
    return <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />;
  };

  return { t, ...rest };
};
