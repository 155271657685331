import React, { useState, ChangeEvent, FormEvent } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
const PhotoUpload = ({
  name,
  path,
  limit = 1,
  existingPhotos,
  onSave,
}: {
  existingPhotos: string[];
  name: string;
  onSave: () => void;
  path: string;
  limit: number;
}) => {
  const [files, setFiles] = useState<File[]>([]);

  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    if (selectedFiles.length > limit) {
      alert(`You can upload up to ${limit} photos.`);
      setFiles([]);
      return;
    } else {
      setFiles(selectedFiles);
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!files.length) {
      alert('Please select some files first.');
      return;
    }

    const formData = new FormData();
    files.forEach((file, index) => {
      const fileName = `${path}${
        existingPhotos.length + index + 1
      }${file.name.substring(file.name.lastIndexOf('.'))}`;
      formData.append('photos', file, fileName);
    });

    try {
      await axios.post(`${serverUrl}/upload`, formData);

      setTimeout(() => {
        onSave();
        setFiles([]);
      }, 500);
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Failed to upload files.');
    }
  };

  const [deleteItem, setDeleteItem] = useState('');
  const [deleteVisible, setDeleteVisible] = useState(false);
  const prepareToDelete = (item: string) => {
    setDeleteItem(item);
    setDeleteVisible(true);
  };

  const deletePhoto = async () => {
    try {
      const cleanedFileName = deleteItem.replace(/^.*\/uploads\/|(\?.*)$/g, '');
      await axios.delete(`${serverUrl}/deletePhoto/${cleanedFileName}`);
      setDeleteVisible(false);
      setDeleteItem('');
      setTimeout(() => {
        onSave();
      }, 500);
    } catch (error) {
      setDeleteVisible(false);
      setDeleteItem('');
      alert('не вдалось видалити фото'); // Повідомлення про помилку
    }
  };
  return (
    <div
      className='container mt-5'
      style={{
        borderRadius: 20,
        padding: 20,
        backgroundColor: '#00000005',
      }}
    >
      {deleteVisible && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: '#00000010',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: 20,
              padding: 20,
            }}
          >
            <div>Видалити фото?</div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginTop: 10,
              }}
            >
              <div
                style={{
                  paddingLeft: 14,
                  paddingRight: 14,
                  backgroundColor: 'gray',
                  cursor: 'pointer',
                  borderRadius: 30,
                }}
                onClick={() => {
                  setDeleteItem('');
                  setDeleteVisible(false);
                }}
              >
                ні
              </div>
              <div
                style={{
                  paddingLeft: 14,
                  paddingRight: 14,
                  backgroundColor: 'red',
                  cursor: 'pointer',
                  borderRadius: 30,
                }}
                onClick={deletePhoto}
              >
                так
              </div>
            </div>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit} className='shadow p-4 rounded bg-light'>
        <div className='mb-3'>
          <label htmlFor='photos' className='form-label'>
            {name}
          </label>
          <input
            type='file'
            id='photos'
            multiple
            accept='image/*'
            onChange={handleFileChange}
            className='form-control'
          />
        </div>

        <button type='submit' className='btn btn-primary w-100'>
          Завантажити
        </button>
      </form>

      <div className='mt-4'>
        <h3>Нові фото</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {files.map((file, index) => (
            <div
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 20,
              }}
              key={index}
              className='list-group-item'
            >
              {file.type.startsWith('image/') && (
                <img
                  alt={file.name}
                  src={URL.createObjectURL(file)}
                  style={{
                    width: '150px',
                    height: 'auto',
                  }}
                />
              )}
            </div>
          ))}
        </div>
        <h3 className='mt-4'>Старі фото</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {existingPhotos.map((file, index) => (
            <div
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 20,
                position: 'relative',
              }}
              key={index}
              className='list-group-item'
            >
              <div
                style={{
                  height: 20,
                  width: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingBottom: 1,
                  borderRadius: 100,
                  border: '1px solid white',
                  display: 'flex',
                  position: 'absolute',
                  top: 20,
                  right: 20,
                  cursor: 'pointer',
                  color: 'red',
                  fontSize: 16,
                  fontWeight: '700',
                }}
                onClick={() => prepareToDelete(file)}
              >
                X
              </div>
              <img
                alt={file}
                src={file}
                style={{
                  width: '160px',
                  height: 'auto',
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotoUpload;
