import React, { useState } from 'react';
import '../bootstrap/css/bootstrap.min.css';
import { Col, Container, Form, Row } from 'react-bootstrap';
import StyledButton from './styledCompornents/button';
import CompletedAnimation from './animations/CompletedAnimation';
import ElementSlideAnimation from './animations/ElementSlideAnimation';

import { useTranslation } from '../i18n/translator';
import { submitForm } from '../thunk/sendFormData';
import { stripHTMLTags } from '../../src/helpers';

const FeedbackForm: React.FC = () => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');
  const [contact, setContact] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [response, setResponse] = useState<string>('');
  const [service, setService] = useState<string>('');
  const [contactError, setContactError] = useState<string>('');
  const [contactText, setContactText] = useState<string>('email_phone');
  const [animationStart, setAnimationStart] = useState<boolean>(false);
  const validateContact = (value: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10,}$/; // Adjust this pattern according to your phone format requirements
    if (emailPattern.test(value) || phonePattern.test(value)) {
      setContactError('');
      if (emailPattern.test(value)) {
        setContactText('Email');
      } else if (phonePattern.test(value)) {
        setContactText('phone_number');
      } else {
        setContactText('email_phone');
      }
      return true;
    } else {
      setContactError('Введіть дійсний email або номер телефону');
      return false;
    }
  };

  const handleContactChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setContact(e.target.value);
    validateContact(e.target.value.toString());
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setAnimationStart(true);
      setTimeout(() => setAnimationStart(false), 2000);
      await submitForm({
        name: name,
        contact: contact,
        message: message,
        option: service,
      }).then((res) => {
        if (res.success) {
          setResponse('Feedback submitted successfully');
          setContact('');
          setName('');
          setMessage('');
        }
      });
    } catch (err) {
      setResponse('Failed to submit feedback');
    }
  };

  return (
    <Container style={{ marginTop: 50, marginBottom: 100 }}>
      <div className='w-100' style={{ maxWidth: '95vw' }}>
        <h1
          className='text-center'
          style={{
            textAlign: 'center',
            fontWeight: '700',
          }}
        >
          {t('translate:first_step')}
        </h1>
        <h2
          className='text-center'
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            lineHeight: '24.2px',
            fontWeight: '400',
            maxWidth: 560,
            marginBottom: 40,
          }}
        >
          {t('translate:contact_us')}
        </h2>
        <Form onSubmit={handleSubmit}>
          <Row
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              justifyContent: 'center',
            }}
          >
            <Col xs={6} md={5} className='mb-3'>
              <Form.Group controlId='formName'>
                <Form.Label>{t('translate:name')}</Form.Label>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: 15,
                  }}
                >
                  <ElementSlideAnimation animationStarted={animationStart} />
                  <Form.Control
                    type='text'
                    value={name}
                    className='form-input'
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
              </Form.Group>
            </Col>
            <Col xs={6} md={5} className='mb-3'>
              <Form.Group controlId='formContact'>
                <Form.Label>{t(`translate:${contactText}`)}</Form.Label>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: 15,
                  }}
                >
                  <ElementSlideAnimation animationStarted={animationStart} />
                  <Form.Control
                    type='text'
                    value={contact}
                    className='form-input'
                    onChange={handleContactChange}
                    isInvalid={!!contactError}
                    required
                  />
                </div>
                <Form.Control.Feedback type='invalid'>
                  {t(`translate:${contactError}`)}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Col
            md={10}
            sm={12}
            className='mb-3'
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              justifyContent: 'center',
            }}
          >
            <Form.Group controlId='formService' className='mb-3 '>
              <Form.Label>{t('translate:choose_service')}</Form.Label>
              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: 15,
                }}
              >
                <ElementSlideAnimation animationStarted={animationStart} />

                <Form.Control
                  as='select'
                  value={service}
                  className='form-input custom-select'
                  onChange={(e) => setService(e.target.value)}
                  required
                >
                  <option value='Не обрано'>
                    {stripHTMLTags(
                      t('translate:choose_service', { onlyText: true })
                    )}
                  </option>
                  <option value={'Митне оформлення вантажів'}>
                    {t('translate:customs_clearance', { onlyText: true })}
                  </option>
                  <option value={'Митний склад'}>
                    {t('translate:customs_warehouse', { onlyText: true })}
                  </option>
                  <option value={'Оренда складських приміщень'}>
                    {t('translate:warehouse_lease', { onlyText: true })}
                  </option>
                  <option value={'Склад Тимчасового Зберігання'}>
                    {t('translate:temporary_storage', { onlyText: true })}
                  </option>
                  <option value={'Оренда офісних приміщень'}>
                    {t('translate:office_lease', { onlyText: true })}
                  </option>
                  <option value={'Міжнародні та внутрішні перевезення'}>
                    {t('translate:transportation', { onlyText: true })}
                  </option>
                  <option value={'Оренда техніки'}>
                    {t('translate:equipment_rental', { onlyText: true })}
                  </option>
                  <option value={'Автосервіс'}>
                    {t('translate:car_service', { onlyText: true })}
                  </option>
                  <option value={'Комплексне харчування'}>
                    {t('translate:catering', { onlyText: true })}
                  </option>
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
          <Col
            md={10}
            sm={12}
            className='mb-3'
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              justifyContent: 'center',
            }}
          >
            <Form.Group controlId='formMessage' className='mb-3'>
              <Form.Label>
                {stripHTMLTags(t('translate:message', { onlyText: true }))}:
              </Form.Label>

              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  borderRadius: 15,
                }}
              >
                <ElementSlideAnimation animationStarted={animationStart} />
                <Form.Control
                  as='textarea'
                  className='form-input'
                  rows={4}
                  style={{ zIndex: 2 }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
            </Form.Group>
          </Col>

          <Col
            md={11}
            sm={12}
            style={{ alignItems: 'flex-start' }}
            className='mb-3 d-flex justify-content-md-end justify-content-center '
          >
            <StyledButton
              type='submit'
              action={handleSubmit}
              text={stripHTMLTags(t('translate:send', { onlyText: true }))}
              style={{
                width: '90%',

                marginBottom: 'auto',
              }}
            >
              <CompletedAnimation animationStarted={animationStart} />
            </StyledButton>
          </Col>
        </Form>
        {response && <p className='mt-3 text-center'>{response}</p>}
      </div>
    </Container>
  );
};

export default FeedbackForm;
