import React, { useState } from 'react';
import Logo from '../assets/logo.webp';
import '../../bootstrap/css/bootstrap.min.css';
import { motion } from 'framer-motion';
import LangControl from './LangSwitch';
import Line from '../line';
import { useTranslation } from '../../i18n/translator';
import {
  handlePhoneClick,
  scrollToElement,
  stripHTMLTags,
} from '../../helpers';
import './styles.css'; // Підключимо стилі
import Phone from '../assets/phone.svg';
import { Col, Image, Row, Button } from 'react-bootstrap';

const Header = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(1);
  const buttons = [
    {
      id: 1,
      text: stripHTMLTags(t('translate:about_us', { onlyText: true })),
      href: 'about-us',
    },
    {
      id: 2,
      text: stripHTMLTags(t('translate:services', { onlyText: true })),
      href: 'services',
    },
    {
      id: 3,
      text: stripHTMLTags(t('translate:contacts', { onlyText: true })),
      href: 'contacts',
    },
    {
      id: 4,
      text: stripHTMLTags(t('translate:location', { onlyText: true })),
      href: 'location',
    },
  ];
  const phones = [
    '032-244-83-97',
    '068-791-99-90',
    '067-149-81-99',
    '067-149-81-41',
  ];

  return (
    <header
      className='header-container'
      style={{ padding: 0, maxWidth: '99.9vw' }}
    >
      <div className='header-container slide-down animate'>
        <Row xs={12} md={2} style={{ width: '95vw' }}>
          <Col xs={6} sm={6} md={2} className='logo-container'>
            <Image
              src={Logo}
              className='header-logo'
              alt={'Митний брокер львів'}
            />
          </Col>
          <Col sm={12} md={8} className='phones-container'>
            <Row className='phones-row'>
              {phones.map((phone) => (
                <div
                  key={phone}
                  className='phone-number'
                  onClick={() => handlePhoneClick(phone)}
                >
                  <img src={Phone} alt={'Оренда офісу львів'} />
                  {phone}
                </div>
              ))}
            </Row>
          </Col>
          <Col xs={6} sm={6} md={2} className='lang-container'>
            <LangControl />
          </Col>
        </Row>
        <Col xs={12} md={9} className='buttons-conteiner'>
          <Row className='buttons-row'>
            {buttons.map((button) => (
              <Col xs='auto' key={button.id} className='button-wrapper'>
                <Button
                  className={`nav-button ${
                    active === button.id ? 'active' : ''
                  }`}
                  onClick={() => {
                    setActive(button.id);
                    scrollToElement(button.href);
                  }}
                >
                  {button.text}
                </Button>
                {active === button.id && (
                  <motion.div
                    className='active-indicator'
                    layoutId='activeIndicator'
                  >
                    {button.text}
                  </motion.div>
                )}
              </Col>
            ))}
          </Row>
        </Col>
        <Line width={'90vw'} left={'5vw'} top={'99%'} />
      </div>
    </header>
  );
};

export default Header;
