import { motion, AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from '../../i18n/translator';


export enum Langs {
  en = 'en',
  pl = 'pl',
  ko = 'ko',
  ua = 'ua',
}

const LangControl = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const changeLanguage = (lng: Langs) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false);
  };

  const languages = [
    { code: Langs.ua, label: 'УКР' },
    { code: Langs.en, label: 'ENG' },
    { code: Langs.pl, label: 'POL' },
    { code: Langs.ko, label: 'KOR' },
  ];

  const currentLanguage = languages.find((lang) => lang.code === i18n.language);

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        backgroundColor: '#EAEAEA',
        marginLeft: 'auto',
        marginRight: '2vw',
        borderRadius: 15,
        borderBottomLeftRadius: dropdownOpen ? 0 : 15,
        borderBottomRightRadius: dropdownOpen ? 0 : 15,
      }}
      className='lang-item'
      onClick={() => setDropdownOpen(!dropdownOpen)}
    >
      <p
        style={{
          marginLeft: 'auto',
          marginTop: 'auto',
          marginBottom: 'auto',
          marginRight: 'auto',
        }}
      >
        {currentLanguage ? currentLanguage.label : 'Select'}
      </p>
      <AnimatePresence>
        {dropdownOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }} // Optional transition duration
            style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              backgroundColor: '#EAEAEA',
              borderRadius: '0 0 15px 15px',
              overflow: 'hidden',
              zIndex: 999,
            }}
          >
            {languages
              .filter((lang) => lang.code !== i18n.language)
              .map((lang, index) => (
                <motion.div
                  className='lang-item'
                  key={lang.code}
                  onClick={() => changeLanguage(lang.code)}
                  style={{
                    borderBottom:
                      index < languages.length - 2 ? '1px solid #ccc' : 'none',
                    backgroundColor: '#d9d9d9',
                  }}
                  whileHover={{ backgroundColor: '#ccc' }} // Optional hover effect
                >
                  {lang.label}
                </motion.div>
              ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LangControl;
