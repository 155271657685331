import React from 'react';
import '../DesriptionBlock/style.css';
import './styles.css';

import { useTranslation } from '../../i18n/translator';
import { stripHTMLTags } from '../../../src/helpers';
export type AdProps = {
  text: string;
  num: string;
  title: string;
  borderHide: 'left' | 'right';
  topBorder?: boolean;
  className?: string;
  style?: any;
  textAlign?: 'start' | 'end';
};

const AdvantageBlock = ({
  text,
  num,
  title,
  borderHide,
  textAlign = 'start',
  style = {},
}: AdProps) => {
  const hideL = borderHide === 'left';

  const { t } = useTranslation();

  return (
    <div
      style={{
        padding: 50,
        paddingTop: 20,
        paddingBottom: 20,
        border: '1px solid #DFDFDF',
        borderLeft: `${hideL ? '0' : '0.6px solid #DFDFDF'}`,
        borderRight: `${hideL ? '0.6px solid #DFDFDF' : '0'}`,
        ...style,
      }}
    >
      <div
        className={
          hideL ? 'slide-left animate margin-left' : 'slide-right animate'
        }
      >
        <p
          style={{
            fontSize: 36,
            fontWeight: 500,
            textAlign,
            color: '#1500ff',
            marginBottom: 40,
          }}
        >
          {stripHTMLTags(num)}
        </p>
        <p
          className='big-text'
          style={{
            textAlign: 'left',
            marginLeft: hideL ? 'auto' : 0,
            fontWeight: 600,
            marginBottom: 20,
            maxWidth: '70vw',
          }}
        >
          {t(`translate:${title}`, { onlyText: true })}
        </p>
        <div
          className='text'
          style={{
            textAlign,
            width: '100%',
            color: '#909da2',
            fontWeight: 500,
          }}
        >
          {t(`translate:${text}`, { onlyText: true })}
        </div>
      </div>
    </div>
  );
};
export default AdvantageBlock;
