import React, { useState, useEffect, useRef } from "react";
import { Checkmark, Ring } from "./Styled";

type Props = {
  animationStarted: boolean;
  bgColor?: string;
};

const CompletedAnimation = ({
  animationStarted = false,
  bgColor = "#0D1C45",
}: Props) => {
  const [reverseAnimation, setReverseAnimation] = useState(false);
  const prevAnimation = useRef(false);

  useEffect(() => {
    if (!animationStarted && prevAnimation.current) {
      prevAnimation.current = false;
      setReverseAnimation(true);
      setTimeout(() => {
        setReverseAnimation(false);
      }, 800);
    } else {
      prevAnimation.current = animationStarted;
      setReverseAnimation(false);
    }
  }, [animationStarted]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor:
          animationStarted || reverseAnimation
            ? bgColor
            : "rgba(255, 255, 255, 0.0)",
        zIndex: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {animationStarted && !reverseAnimation && (
        <>
          <Ring reverse="false">
            <circle cx="20" cy="20" r="18" />
          </Ring>
          <Checkmark>✔</Checkmark>
        </>
      )}

      {!animationStarted && reverseAnimation && (
        <>
          <Ring reverse="true">
            <circle cx="20" cy="20" r="18" />
          </Ring>
          <Checkmark>✔</Checkmark>
        </>
      )}
    </div>
  );
};

export default CompletedAnimation;
