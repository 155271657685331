import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../assets/logo.webp';
import { handlePhoneClick, scrollToElement } from '../../helpers';
import PrivacyPolicyModal from './conf-policy';

const Footer = () => {
  const [policyVisible, setPolicyVisible] = useState(false);
  return (
    <div
      style={{
        backgroundColor: '#0D1C45',
        color: '#fff',
        overflow: 'hidden',
        maxWidth: '99.9vw',
      }}
    >
      <PrivacyPolicyModal
        policyVisible={policyVisible}
        setPolicyVisible={setPolicyVisible}
      />
      <div className='row py-4'>
        <div className='col-md-4'>
          <img
            src={Logo} // Замініть на шлях до вашого логотипу
            alt={'Оренда навантажувача'}
            className='mb-3'
            style={{
              backgroundColor: '#fff',
              padding: 10,
              paddingLeft: 50,
            }}
          />
        </div>
        <div className='col-md-4'>
          <p>Митний пост «Городок».</p>
          <p>ВМО №1, ВМО №2</p>
          <p>(«Скнилів , транспортний відділ»)</p>
          <div style={{ display: 'flex' }}>
            <ul className='list-unstyled' style={{ justifyContent: 'center' }}>
              <li
                className='phone-number'
                color='#fff'
                onClick={() => handlePhoneClick('0322448397')}
                style={{ color: '#fff' }}
              >
                Тел. 032-244-83-97
              </li>
              <li
                color='#fff'
                className='phone-number'
                onClick={() => handlePhoneClick('0671498199')}
                style={{ color: '#fff' }}
              >
                Тел. 067-149-81-99
              </li>
              <li
                className='phone-number'
                style={{ color: '#fff' }}
                onClick={() => handlePhoneClick('0671498141')}
              >
                Тел. 067-149-81-41
              </li>
              <li
                className='phone-number'
                onClick={() => handlePhoneClick('0687919990')}
                style={{ color: '#fff' }}
              >
                Тел. 068-791-99-90
              </li>
            </ul>
            <ul className='list-unstyled' style={{ marginLeft: '10%' }}>
              <li>zahidresyrsmb@ukr.net</li>
              <li>zravto@gmail.com</li>
            </ul>
          </div>
        </div>
        <div className='col-md-4' style={{ display: 'flex' }}>
          <ul className='list-unstyled'>
            <li
              onClick={() => {
                scrollToElement('about-us');
              }}
            >
              Про нас
            </li>
            <li
              onClick={() => {
                scrollToElement('services');
              }}
            >
              Послуги
            </li>
            <li
              onClick={() => {
                scrollToElement('contacts');
              }}
            >
              Контакти
            </li>
            <li
              onClick={() => {
                scrollToElement('location');
              }}
            >
              Місцезнаходження
            </li>
          </ul>
          <ul className='list-unstyled' style={{ marginLeft: '10%' }}>
            <li
              onClick={() => {
                scrollToElement('contacts');
              }}
            >
              Facebook
            </li>
            <li
              onClick={() => {
                scrollToElement('contacts');
              }}
            >
              Twitter
            </li>
            <li
              onClick={() => {
                scrollToElement('contacts');
              }}
            >
              Instagram
            </li>
          </ul>
        </div>
      </div>
      <div
        className='row'
        onClick={() => setPolicyVisible(true)}
        style={{ cursor: 'pointer' }}
      >
        <div className='col-12 text-center'>
          <p>&copy; 2024 Політика конфіденційності</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
