import React from 'react';
import '../../bootstrap/css/bootstrap.min.css';
import './styles.css';
import { useTranslation } from '../../../src/i18n/translator';

interface HtmlObject {
  dangerouslySetInnerHTML: {
    __html: string;
  };
}

interface HtmlObject {
  dangerouslySetInnerHTML: {
    __html: string;
  };
}

const CustomComponentFromHtml: React.FC<HtmlObject> = ({
  dangerouslySetInnerHTML,
}) => {
  const htmlString = dangerouslySetInnerHTML.__html;

  // Функція для форматування тексту: обробляємо лише цифри, без одиниць вимірювання
  const formatHtml = (html: string) => {
    const regex = /(\d+)(?=[^\d]|$)/g;

    // Обгортаємо цифри в <span> для зміни кольору
    const formattedHtml = html.replace(
      regex,
      (match) => `<span style="color: blue;">${match}</span>`
    );

    return formattedHtml;
  };

  // Функція для витягання зображення з HTML
  const extractImage = (html: string) => {
    const imageRegex = /<img [^>]*src="([^"]+)"[^>]*>/i;
    const match = html.match(imageRegex);
    const imageUrl = match ? match[1] : null;

    // Видаляємо тег <img> із HTML
    const cleanedHtml = html.replace(imageRegex, '');

    return { imageUrl, cleanedHtml };
  };

  // Витягуємо зображення та відформатований текст
  const { imageUrl, cleanedHtml } = extractImage(htmlString);
  const finalHtml = formatHtml(cleanedHtml);

  return (
    <div style={{ textAlign: 'center', maxWidth: 220 }}>
      {imageUrl && (
        <img
          src={imageUrl}
          height={150}
          width={150}
          style={{ marginBottom: '10px' }}
          alt={'Захід ресурс'}
        />
      )}
      <div dangerouslySetInnerHTML={{ __html: finalHtml }} />
    </div>
  );
};

const AdditionalAdvanatgesInfo = () => {
  const { t } = useTranslation();

  const t1 = t('translate:advantage_header_1') as any;
  const t2 = t('translate:advantage_header_2') as any;
  const t3 = t('translate:advantage_header_3') as any;
  const t4 = t('translate:advantage_header_4') as any;
  const t5 = t('translate:advantage_header_5') as any;
  const t6 = t('translate:advantage_header_6') as any;
  return (
    <div
      style={{
        marginTop: 100,
        marginBottom: 60,
        paddingLeft: '10%',
        paddingRight: '10%',
      }}
    >
      <div
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
      >
        <div
          style={{ flex: '1 1 33%', display: 'flex', justifyContent: 'center' }}
        >
          <CustomComponentFromHtml
            dangerouslySetInnerHTML={t1?.props?.dangerouslySetInnerHTML}
          />
        </div>
        <div
          style={{ flex: '1 1 33%', display: 'flex', justifyContent: 'center' }}
        >
          <CustomComponentFromHtml
            dangerouslySetInnerHTML={t2?.props?.dangerouslySetInnerHTML}
          />
        </div>
        <div
          style={{ flex: '1 1 33%', display: 'flex', justifyContent: 'center' }}
        >
          <CustomComponentFromHtml
            dangerouslySetInnerHTML={t3?.props?.dangerouslySetInnerHTML}
          />
        </div>
        <div
          style={{ flex: '1 1 50%', display: 'flex', justifyContent: 'center' }}
        >
          <CustomComponentFromHtml
            dangerouslySetInnerHTML={t4?.props?.dangerouslySetInnerHTML}
          />
        </div>
        <div
          style={{ flex: '1 1 50%', display: 'flex', justifyContent: 'center' }}
        >
          <CustomComponentFromHtml
            dangerouslySetInnerHTML={t5?.props?.dangerouslySetInnerHTML}
          />
        </div>
        <div
          style={{
            flex: '1 1 50%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CustomComponentFromHtml
            dangerouslySetInnerHTML={t6?.props?.dangerouslySetInnerHTML}
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalAdvanatgesInfo;
