import styled, { keyframes, css } from "styled-components";

export const slideAnimation = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
`;
export const slideOutAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

export const ringAnimation = keyframes`
  0% { stroke-dasharray: 0, 100; }
  100% { stroke-dasharray: 100, 0; }
`;

export const ringReverseAnimation = keyframes`
  0% { stroke-dasharray: 100, 0; }
  100% { stroke-dasharray: 0, 100; }
`;

export const Slide = styled.div<{ reverse?: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0d1c45;
  animation: ${(props) =>
    props.reverse === "true"
      ? css`
          ${slideOutAnimation} 1s forwards
        `
      : css`
          ${slideAnimation} 1s forwards
        `};
`;

export const Ring = styled.svg<{ reverse?: string }>`
  width: 40px;
  height: 40px;
  circle {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 0, 100;
    animation: ${(props) =>
      props.reverse === "true"
        ? css`
            ${ringReverseAnimation} 0.8s forwards
          `
        : css`
            ${ringAnimation} 0.8s forwards
          `};
  }
`;

export const Checkmark = styled.div`
  position: absolute;
  font-size: 18px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
