import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Partners from './DesriptionBlock/Partners';
import ConsultBlock from './ConsultBlock/ConsultBlock';
import Services from './Services/Service';
import Advantages from './Advantages/Advantages';
import Documents from './Documents/Documents';
import FeedbackForm from './FeedbackForm';
import MapBlock from './MapBlock/MapBlock';
import Contacts from './Contacts/Contacts';
import TopContent from './DesriptionBlock/TopContent';
import SuccessModal from './successModalControl';
import LanguageLoader from '../../src/i18n/LangsLoader';

const MainScreen: React.FC = () => {
  return (
    <LanguageLoader>
      <Header />
      <div style={{ flex: 1, maxWidth: '99.9vw', overflow: 'hidden' }}>
        <TopContent />
        <Partners />
        <MapBlock />
        <ConsultBlock />
        <Services />
        <Advantages />
        <Documents />
        <FeedbackForm />
        <Contacts />
        <Footer />
        <SuccessModal />
      </div>
    </LanguageLoader>
  );
};

export default MainScreen;
