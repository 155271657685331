import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import en from './en.json';
import ua from './ua.json';
import pl from './pl.json';
import ko from './ko.json';
// Функція для зберігання перекладів у localStorage
const saveTranslationsToLocalStorage = (lang: string, translations: any) => {
  localStorage.setItem(`translations_${lang}`, JSON.stringify(translations));
};

// Функція для отримання перекладів з localStorage
export const fetchTranslations = async (lang: 'ua' | 'ko' | 'pl' | 'en') => {
  try {
    const response = await fetch(
      `https://zahid-resource.com/api/translations/${lang}`
    );
    if (!response.ok) {
      throw new Error('Failed to fetch translations');
    }

    const res = await response.json();

    reserve[lang] = res;

    saveTranslationsToLocalStorage(lang, res);
    i18next.addResources(lang, 'translate', res);

    return res;
  } catch (error) {
    console.error('Error fetching translations, using reserve:', error);
    return reserve[lang];
  }
};

type Translations = {
  en: { [key: string]: string };
  ua: { [key: string]: string };
  pl: { [key: string]: string };
  ko: { [key: string]: string };
};

const reserve: Translations = {
  en,
  ua,
  pl,
  ko,
};

Object.keys(reserve).forEach((key) => {
  const storedTranslations = localStorage.getItem(`translations_${key}`);
  const data = storedTranslations ? JSON.parse(storedTranslations) : null;

  if (data) {
    reserve[key as keyof Translations] = data;
  }
});

i18next.use(initReactI18next).init({
  lng: 'ua',
  fallbackLng: 'ua',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    translate: reserve,
  },
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  },
});

export default i18next;
