import { AdProps } from './AdvantageBlock';

export const advantages: AdProps[] = [
  {
    text: 'advantage_text_1',
    num: '01',
    title: 'advantage_name_1',
    borderHide: 'right',
  },
  {
    text: 'advantage_text_2',
    num: '02',
    title: 'advantage_name_2',
    borderHide: 'left',
  },
  {
    text: 'advantage_text_3',
    num: '03',
    title: 'advantage_name_3',
    borderHide: 'right',
  },
  {
    text: 'advantage_text_4',
    num: '04',
    title: 'advantage_name_4',
    borderHide: 'left',
  },
  {
    text: 'advantage_text_5',
    num: '05',
    title: 'advantage_name_5',
    borderHide: 'right',
  },
  {
    text: 'advantage_text_6',
    num: '06',
    title: 'advantage_name_6',
    borderHide: 'left',
  },
  {
    text: 'advantage_text_7',
    num: '07',
    title: 'advantage_name_7',
    borderHide: 'right',
  },
  {
    text: 'advantage_text_8',
    num: '08',
    title: 'advantage_name_8',
    borderHide: 'left',
  },
  {
    text: 'advantage_text_9',
    num: '09',
    title: 'advantage_name_9',
    borderHide: 'right',
  },
  {
    text: 'advantage_text_10',
    num: '10',
    title: 'advantage_name_10',
    borderHide: 'left',
  },
];
