import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../DesriptionBlock/style.css';
import AdvantageBlock from './AdvantageBlock';
import { advantages } from './constants';
import AdditionalAdvanatgesInfo from './AdditionalInfo';
import { useTranslation } from '../../i18n/translator';
import { splitArray } from '../../../src/helpers';

const Advantages = () => {
  const { t } = useTranslation();
  const { oddIndices: first, evenIndices: second } = splitArray(advantages);

  return (
    <Container style={{ marginTop: 60, marginBottom: 60 }}>
      <AdditionalAdvanatgesInfo />
      <Col sm={12} style={{ padding: 0, width: '95vw' }}>
        <Row className='adv-sm'>
          <div
            style={{
              padding: 20,
              
              textAlign: 'left',
            }}
            className=' col-md-6 col-sm-12'
          >
            <h1 className='slide-left animate'>
              {t('translate:advantages')}
              {t('translate:experience')}
            </h1>
          </div>
          {advantages.map((el, index) => (
            <span key={`advantage1-${index}`} style={{ position: 'relative' }}>
              <AdvantageBlock
                text={el.text}
                title={el.title}
                num={el.num}
                textAlign={index % 2 === 0 ? 'start' : 'end'}
                className='single-adv'
                borderHide={el.borderHide}
              />
             
            </span>
          ))}
        </Row>
        <Row className='adv-lg'>
          <Col md={6} sm={0} style={{ paddingRight: 0 }}>
            <div
              style={{
                padding: 50,
                paddingBottom: 30,
                paddingLeft: 0,
                border: '1px solid  #DFDFDF',
                borderLeftWidth: 0,
                borderTopWidth: 0,
              }}
            >
              <h1 className='slide-left animate'>
                <div
                  style={{
                    marginBottom: -10,
                  }}
                >
                  {t('translate:advantages')}
                </div>
                {t('translate:experience')}
              </h1>
            </div>
            {first.map((el, index) => (
              <AdvantageBlock
                key={`advantage1-${index}`}
                text={el.text}
                title={el.title}
                num={el.num}
                borderHide={el.borderHide}
              />
            ))}
          </Col>
          <Col md={6} sm={0} style={{ paddingLeft: 0 }}>
            {second.map((el, index) => (
              <AdvantageBlock
                key={`advantage1-${index}`}
                text={el.text}
                title={el.title}
                num={el.num}
                borderHide={el.borderHide}
              />
            ))}
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default Advantages;
