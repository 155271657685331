import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Pagination,
  Table,
  Button,
  FormControl,
  InputGroup,
  Modal,
  Form,
  ListGroup,
} from 'react-bootstrap';
import { io } from 'socket.io-client';
import { FaCheck } from 'react-icons/fa';

interface Request {
  id: number;
  name: string;
  contact: string;
  message: string;
  status: string;
  created_at: string;
  [key: string]: any;
}

const AdminDashboard: React.FC = () => {
  const [requests, setRequests] = useState<Request[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [requestsPerPage] = useState<number>(15);
  const [sortField, setSortField] = useState<string>('created_at');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL || 'zahid-resource.com';

  const [show, setShow] = useState(false);
  const [emailList, setEmails] = useState<{ email: string }[]>([]);
  const [newEmail, setNewEmail] = useState('');

  // Відкриття/закриття модального вікна
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Отримати список емейлів
  const getEmails = useCallback(
    async (token: any) => {
      try {
        const response = await axios.get(`${serverUrl}/emails`, {
          headers: {
            Authorization: token,
          },
        });
        setEmails(response.data.emails);
        return response.data;
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    },
    [serverUrl]
  );

  // Додати новий емейл
  const [error, setError] = useState('');

  const validateEmail = useCallback((email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex
    return regex.test(email);
  }, []);

  const addEmail = useCallback(async () => {
    if (emailList.some((l) => l.email === newEmail)) {
      setError('такий емейл вже існує');
      return;
    }
    if (!validateEmail(newEmail)) {
      setError('Invalid email address');
      return;
    }

    setError('');
    try {
      const token = localStorage.getItem('token');

      await axios.post(
        `${serverUrl}/emails`,
        { email: newEmail },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setNewEmail('');
    } catch (error) {
      console.error('Error adding email:', error);
      throw error;
    }
  }, [emailList, newEmail, serverUrl, validateEmail]);

  // Видалити емейл
  const deleteEmail = useCallback(
    async (email: any) => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.delete(`${serverUrl}/emails/${email}`, {
          headers: {
            Authorization: token,
          },
        });
        return response.data;
      } catch (error) {
        console.error('Error deleting email:', error);
        throw error;
      }
    },
    [serverUrl]
  );
  const fetchRequests = useCallback(
    async (token: string) => {
      try {
        const res = await axios.get(`${serverUrl}/requests`, {
          headers: { Authorization: token },
        });

        setRequests(res.data.requests);
      } catch (err) {
        localStorage.removeItem('token');
        navigate('/admin');
      }
    },
    [navigate, serverUrl]
  );
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/admin');
      return;
    }

    getEmails(token);

    fetchRequests(token);

    const socket = io(serverUrl);
    socket.on('newRequest', (newRequest: Request) => {
      setRequests((prevRequests) => [newRequest, ...prevRequests]);
    });
    socket.on('emailUpdated', (updatedEmails) => {
      if (updatedEmails.message === 'Email deleted') {
        setEmails((prev) =>
          prev.filter((e) => e.email !== updatedEmails.email)
        );
      }
      if (updatedEmails?.id) {
        setEmails((prev) => [...prev, updatedEmails]);
      }
    });
    socket.on('updateRequest', (updatedRequest: Request) => {
      setRequests((prevRequests) =>
        prevRequests.map((request) =>
          request.id === updatedRequest.id ? updatedRequest : request
        )
      );
    });

    return () => {
      socket.disconnect();
    };
  }, [fetchRequests, getEmails, navigate, serverUrl]);

  const handleSort = (field: string) => {
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const handleComplete = async (id: number) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const res = await axios.post(
        `${serverUrl}/requests/${id}/complete`,
        {},
        {
          headers: { Authorization: token },
        }
      );
      setRequests((prevRequests) =>
        prevRequests.map((request) =>
          request.id === res.data.id ? res.data : request
        )
      );
    } catch (err) {
      console.error('Error completing request', err);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredRequests = useMemo(() => {
    return (
      requests?.filter(
        (request) =>
          request.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          request.contact.toLowerCase().includes(searchTerm.toLowerCase())
      ) ?? []
    );
  }, [requests, searchTerm]);

  const sortedRequests = useMemo(() => {
    return [...filteredRequests].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
    });
  }, [filteredRequests, sortField, sortOrder]);

  const indexOfLastRequest = currentPage * requestsPerPage;
  const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
  const currentRequests = sortedRequests.slice(
    indexOfFirstRequest,
    indexOfLastRequest
  );
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(sortedRequests.length / requestsPerPage);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div
      className='container'
      style={{
        width: '90vw',
        maxWidth: 1600,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 20,
          left: 30,
          padding: 10,
          backgroundColor: '#fff',
          borderRadius: 20,
          cursor: 'pointer',
        }}
        onClick={() => navigate('/admin/textEditor')}
      >
        Редактор текстів
      </div>

      <div
        style={{
          position: 'absolute',
          top: 20,
          left: 190,
          padding: 10,
          backgroundColor: '#fff',
          borderRadius: 20,
          cursor: 'pointer',
        }}
        onClick={() => navigate('/admin/photoEditor')}
      >
        Редактор Фото
      </div>

      <h1 className='my-4 text-center'>Залишені запити</h1>
      <InputGroup className='mb-3'>
        <FormControl
          placeholder="Пошук за ім'ям або контактом"
          value={searchTerm}
          onChange={handleSearch}
        />
      </InputGroup>
      <Table striped bordered hover responsive className='table-sm'>
        <thead>
          <tr>
            <th>Ім'я</th>
            <th>Контакт</th>
            <th>Вибрана послуга</th>
            <th>Повідомлення</th>
            <th
              onClick={() => handleSort('created_at')}
              style={{ position: 'relative' }}
            >
              Дата
              <div style={{ position: 'absolute', top: 2, right: 5 }}>
                {sortField === 'created_at'
                  ? sortOrder === 'asc'
                    ? '▲'
                    : '▼'
                  : ''}
              </div>
            </th>
            <th
              onClick={() => handleSort('status')}
              style={{ position: 'relative' }}
            >
              Статус
              <div style={{ position: 'absolute', top: 2, right: 5 }}>
                {sortField === 'status'
                  ? sortOrder === 'asc'
                    ? '▲'
                    : '▼'
                  : ''}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentRequests.map((request) => (
            <tr key={request.id}>
              <td>{request.name}</td>
              <td>{request.contact}</td>
              <td>{request.selectedOption}</td>
              <td>{request.message}</td>
              <td>{new Date(request.created_at).toLocaleString()}</td>
              <td className='text-center' style={{ width: 80 }}>
                {request.status !== 'completed' ? (
                  <Button
                    style={{ marginRight: 'auto', marginLeft: 'auto' }}
                    variant='success'
                    size='sm'
                    onClick={() => handleComplete(request.id)}
                    className='d-flex align-items-center justify-content-center'
                  >
                    <FaCheck />
                  </Button>
                ) : (
                  <FaCheck color='green' />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className='d-flex justify-content-center'>
        <Pagination>
          {pageNumbers.map((number) => (
            <Pagination.Item
              key={number}
              onClick={() => paginate(number)}
              active={number === currentPage}
            >
              {number}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
      <Button
        variant='primary'
        onClick={handleShow}
        style={{ position: 'fixed', top: '10px', right: '10px' }}
      >
        Керувати поштою
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Список пошти для розсилки</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label>Додати нову</Form.Label>
            <Form.Control
              type='email'
              placeholder='Введіть нову пошту'
              value={newEmail}
              onChange={(e) => {
                setNewEmail(e.target.value);
                if (error) setError(''); // Clear error if user starts typing
              }}
              isInvalid={!!error} // Mark input as invalid
              style={{ borderColor: error ? 'red' : '' }} // Change border color based on error
            />
            <Form.Control.Feedback type='invalid'>
              {error}
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant='success' onClick={addEmail}>
            Додати
          </Button>

          <h5 className='mt-4'>Пошта для розсилки</h5>
          <ListGroup>
            {emailList.map((email, index) => (
              <ListGroup.Item
                key={index}
                className='d-flex justify-content-between'
              >
                {email.email}
                <Button
                  variant='danger'
                  size='sm'
                  onClick={() => deleteEmail(email.email)}
                >
                  Видалити
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Закрити
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminDashboard;
