import React, { useMemo } from 'react';
import '../../bootstrap/css/bootstrap.min.css';
import ConsultForm from './Form';
import './styles.css'; // Підключаємо стилі
import ImageSlider from '../animations/ImageSlider';
import { usePhotos } from '../PhotoEditor/PhotoProvider';

const ConsultBlock: React.FC = () => {
  const { photos } = usePhotos();
  const QPhotos = useMemo(
    () => photos.filter((p) => p.includes('consultSlider')),
    [photos]
  );
  return (
    <div className='consult-block-container'>
      <ImageSlider images={QPhotos} />
      <ConsultForm />
    </div>
  );
};

export default ConsultBlock;
