import React, { useEffect, useState } from 'react';
import { fetchTranslations } from './i18n';
import Arrow from '../components/assets/arrow.svg';
import LOGO from '../components/assets/logo.webp';
import '../App.css';
const LanguageLoader = ({ children }: any) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [loading, setLoading] = useState(true);

  const loadLanguages = async () => {
    const languages: ['en', 'ua', 'pl', 'ko'] = ['en', 'ua', 'pl', 'ko'];
    const promises = languages.map((lang) => fetchTranslations(lang));

    try {
      await Promise.all(promises);
      setHidden(true);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      console.error('Error loading languages:', error);
      setLoading(false);
    }
  };

  const callback = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animated');
        observer.unobserve(entry.target);
      }
    });
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const docHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    if (scrollTop / docHeight > 0.1) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    if (loading) {
      loadLanguages();
    }
    if (!loading) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      };

      const observer = new IntersectionObserver(callback, options);
      const targets = document.querySelectorAll('.animate');
      targets.forEach((target) => observer.observe(target));
      window.addEventListener('scroll', handleScroll);
      return () => {
        targets.forEach((target) => observer.unobserve(target));
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [loading]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className={`loader ${!hidden ? '' : 'hidden'}`}>
        <img src={LOGO} alt='Захід ресурс' />
      </div>
      {!loading && children}
      {showScrollButton && (
        <img
          src={Arrow}
          height={50}
          width={50}
          style={{
            position: 'fixed',
            right: '1%',
            cursor: 'pointer',
            bottom: '1%',
            borderRadius: 100,
            zIndex: 100,
            borderWidth: 0,
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
            backgroundColor: '#fff',
            padding: 10,
            rotate: '-90deg',
          }}
          onClick={scrollToTop}
          className='scroll-to-top slide-up animated'
          alt={'оренда складу'}
        />
      )}
    </>
  );
};

export default LanguageLoader;
