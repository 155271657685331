import React, {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import '../../bootstrap/css/bootstrap.min.css';
import StyledButton from '../styledCompornents/button';
import StyledInput from '../styledCompornents/input';
import './style.css';
import CompletedAnimation from '../animations/CompletedAnimation';
import ElementSlideAnimation from '../animations/ElementSlideAnimation';
import { submitForm } from '../../thunk/sendFormData';
import { useTranslation } from '../../i18n/translator';
import { debounce, stripHTMLTags } from '../../../src/helpers';
import Video from '../assets/video.mp4';
import { usePhotos } from '../PhotoEditor/PhotoProvider';
const TopContent = () => {
  const { photos } = usePhotos();
  const photo = useMemo(
    () => photos.find((p) => p.includes('topBg')),
    [photos]
  );
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({ phone: '', name: '' });
  const [animationForm1Started, setAnimationForm1Started] = useState(false);
  const { t } = useTranslation();
  const [bgSize, setBgSize] = useState({ height: 750, width: 1366 });
  const handleDataSubmit = useCallback(() => {
    try {
      if (/^[0-9]{10,}$/.test(phone)) {
        setAnimationForm1Started(true);
        submitForm({ contact: phone }).then((res) => {
          if (res.success) {
            setPhone('');
          } else {
            setErrors({ ...errors, name: 'щось пішло не так' });
          }
        });
        setTimeout(() => {
          setAnimationForm1Started(false);
          setPhone('');
        }, 1201);
      } else {
        alert('введіть коректний номер телефону');
      }
    } catch (e) {
      console.warn(e);
    }
  }, [errors, phone]);

  const handleForm1Submit = useCallback(() => {
    handleDataSubmit();
  }, [handleDataSubmit]);

  const [gridDimensions, setGridDimensions] = useState({
    rows: 10,
    columns: 10,
  });

  const calculateGridDimensions = useCallback(() => {
    const element = document.getElementById('about-us');

    if (element) {
      const rect = element.getBoundingClientRect();
      const parentHeight = rect.height;
      const parentWidth = rect.width;
      const cellWidth = 50;
      const cellHeight = 50;
      const availableWidth = parentWidth * 0.75;
      const availableHeight = parentHeight * 1.1;
      const columns = Math.floor(availableWidth / cellWidth) || 10;
      const rows = Math.floor(availableHeight / cellHeight) || 10;

      setGridDimensions({ rows, columns });
    }
  }, []);
  const debouncedCalculateGridDimensions = useMemo(
    () => debounce(calculateGridDimensions, 1000),
    [calculateGridDimensions]
  );

  useEffect(() => {
    calculateGridDimensions();

    window.addEventListener('resize', debouncedCalculateGridDimensions);

    return () =>
      window.removeEventListener('resize', debouncedCalculateGridDimensions);
  }, [calculateGridDimensions, debouncedCalculateGridDimensions]);
  const randomizeGrid = useCallback(() => {
    const gridCells = document.querySelectorAll('.grid-cell');
    const randomIndices = Array.from(
      {
        length: Math.round(
          (gridDimensions.columns + gridDimensions.rows) / 2 + 10
        ),
      },
      () => Math.floor(Math.random() * gridCells?.length)
    );

    randomIndices.forEach((index, i) => {
      const htmlCell = gridCells[index] as HTMLElement;

      setTimeout(() => {
        htmlCell.classList.add('active');

        setTimeout(() => {
          htmlCell.classList.remove('active');
        }, 2500);
      }, i * 350);
    });
  }, [gridDimensions.columns, gridDimensions.rows]);

  useLayoutEffect(() => {
    randomizeGrid();
  });

  useEffect(() => {
    const intervalId = setInterval(randomizeGrid, 5500);

    const element = document.getElementById('about-us');
    if (element) {
      const rect = element.getBoundingClientRect();
      setBgSize({
        height: rect.height > 0 ? rect.height : bgSize.height,
        width: rect.width > 0 ? rect.width : bgSize.width,
      });
    }

    return () => clearInterval(intervalId);
  }, [bgSize.height, bgSize.width, randomizeGrid]);
  return (
    <>
      <div
        id='about-us'
        style={{
          position: 'relative',
          overflow: 'hidden',
          margin: 0,
          transition: 'padding 10s ease-in-out',
          padding: '5%',
          paddingTop: window.screen.width < 1000 ? 100 : 400,
          paddingBottom: 100,
          width: '100vw',
        }}
      >
        <div
          id='topBg'
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#00000035',
              zIndex: 1,
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              backgroundImage: `url(${photo})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              filter: 'blur(3px)',
            }}
          />
          <div
            style={{
              opacity: 1,
              height: '100%',
              width: '100%',
              zIndex: 2,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '75%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
              }}
            >
              <div className='grid-container'>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, 50px)',
                    gridTemplateRows: 'repeat(auto-fill, 50px)',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}
                >
                  {Array.from({
                    length: gridDimensions.rows * gridDimensions.columns,
                  }).map((_, index) => {
                    let bc = '#e0e0e080';
                    let leftHide = false;
                    let RightHide = false;
                    let topHide = false;
                    let bottomHide = false;
                    if (index < gridDimensions.columns + 1) {
                      topHide = true;
                      bc = '#e0e0e045';
                    }
                    if (
                      index >
                      gridDimensions.columns * gridDimensions.rows -
                        gridDimensions.columns +
                        1
                    ) {
                      bottomHide = true;
                      bc = '#e0e0e045';
                    }
                    if (index % gridDimensions.columns === 0) {
                      bc = '#e0e0e015';
                      leftHide = true;
                    }

                    if ((index - 1) % gridDimensions.columns === 0) {
                      bc = '#e0e0e040';
                    }

                    if ((index + 1) % gridDimensions.columns === 0) {
                      bc = '#e0e0e015';
                      RightHide = true;
                    }

                    if ((index + 2) % gridDimensions.columns === 0) {
                      bc = '#e0e0e040';
                    }

                    return (
                      <div
                        key={'E' + index}
                        style={{
                          height: 50,
                          width: 50,
                          zIndex: 2,
                          borderTop: topHide ? 'none' : `0.5px solid ${bc}`,
                          borderBottom: bottomHide
                            ? 'none'
                            : `0.5px solid ${bc}`,
                          borderRight: RightHide ? 'none' : `0.5px solid ${bc}`,
                          borderLeft: leftHide ? 'none' : `0.5px solid ${bc}`,
                        }}
                      />
                    );
                  })}
                </div>
                {Array.from({
                  length: gridDimensions.rows * gridDimensions.columns,
                }).map((_, index) => {
                  let bg = null;
                  if (index % gridDimensions.columns === 0) {
                    bg = 'linear-gradient(to right, #BDBDBD01, #BDBDBD40)';
                  }

                  if ((index - 1) % gridDimensions.columns === 0) {
                    bg = 'linear-gradient(to right, #BDBDBD39, #BDBDBD90)';
                  }

                  if ((index + 1) % gridDimensions.columns === 0) {
                    bg = 'linear-gradient(to left, #BDBDBD01, #BDBDBD40)';
                  }

                  if ((index + 2) % gridDimensions.columns === 0) {
                    bg = 'linear-gradient(to left, #BDBDBD39, #BDBDBD90)';
                  }

                  const style = bg ? { background: bg } : {};

                  return (
                    <div key={index} className='grid-cell' style={style} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <h1
          className='animate slide-up topText'
          style={{
            maxWidth: 775,
            marginBottom: 20,
            color: '#fff',
            position: 'relative',
            zIndex: 99,
          }}
        >
          {t('translate:tc_main_text')}
        </h1>
        <h3
          className='animate slide-up topText'
          style={{
            maxWidth: 663,
            zIndex: 99,
            color: '#fff',
            position: 'relative',
            marginBottom: 20,
          }}
        >
          {t('translate:tc_secondary')}
        </h3>
        <div
          className='d-flex justify-content-start mt-4'
          style={{
            position: 'relative',
            zIndex: 99,
          }}
        >
          <div
            style={{
              backgroundColor: '#eaeaea',
              position: 'relative',
              flexDirection: 'row',
              display: 'flex',
              borderRadius: 15,
              marginBottom: 30,
              maxWidth: '95vw',
              width: 500,
              padding: 5,
              overflow: 'hidden',
              justifyContent: 'space-between',
            }}
            className='slide-left animate'
          >
            <StyledInput
              id='topContentInput'
              value={phone}
              placeholder={stripHTMLTags(
                t('translate:phone_number', { onlyText: true })
              )}
              onChange={(e: string) => {
                setPhone(e);
                setErrors({ ...errors, phone: '' });
              }}
            />
            <StyledButton
              text={t('translate:send', { onlyText: true })}
              action={handleForm1Submit}
              style={{ width: 166, zIndex: 2 }}
            >
              <CompletedAnimation animationStarted={animationForm1Started} />
            </StyledButton>
            <ElementSlideAnimation animationStarted={animationForm1Started} />
          </div>
        </div>
        {errors.phone && <p style={{ color: 'red' }}>{errors.phone}</p>}
      </div>
      <div className='media-container slide-up animated'>
        <Suspense fallback={<div></div>}>
          <video className='video-background' autoPlay muted loop playsInline>
            <source src={Video} type='video/mp4' />
          </video>
        </Suspense>
      </div>
    </>
  );
};

export default TopContent;
