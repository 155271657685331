import React from 'react';
import { Convert, X } from './assets/serviceImages';
import { useTranslation } from '../i18n/translator';

export function showSuccessModal() {}

export function closeSuccessModal() {
  const el = document.getElementById('success_modal');

  if (el) {
    el.style.opacity = '0';
    setTimeout(() => {
      el.style.visibility = 'hidden';
    }, 500);
  }
}
const SuccessModal = () => {
  const { t } = useTranslation();

  return (
    <div
      id='success_modal'
      style={{
        position: 'fixed',
        top: 0,
        visibility: 'hidden',
        left: 0,
        height: '100vh',
        width: '100vw',
        backgroundColor: '#00000099',
        transition: 'opacity 0.5s ease-in-out',
        zIndex: 999,
        opacity: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          height: 416,
          maxWidth: '80vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          borderRadius: 10,
          position: 'relative',
          textAlign: 'center',
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 8,
            cursor: 'pointer',
            padding: 10,
            zIndex: 1000,
          }}
          onClick={() => {
            closeSuccessModal();
          }}
        >
          <img src={X} alt='close' />
        </div>
        <img src={Convert} alt={'success'} />
        <h1>{t('translate:thanks')}</h1>
        <h2 style={{ maxWidth: 500 }}>{t('translate:success_form_text')}</h2>
      </div>
    </div>
  );
};

export default SuccessModal;
