import React from 'react';
import PhotoUpload from './PhotoEditor';
import { usePhotos } from './PhotoProvider';

const PhotoEditorScreen = () => {
  const { photos, fetchPhotos  } = usePhotos();

  return (
    <div>
      <PhotoUpload
        name='Фон головного екрану'
        limit={1}
        path='topBg'
        existingPhotos={photos.filter((p) => p.includes('topBg'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Партнери'
        limit={5}
        path='partners'
        existingPhotos={photos.filter((p) => p.includes('partners'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Сдайдер над Замовити консультацію'
        limit={10}
        path='consultSlider'
        existingPhotos={photos.filter((p) => p.includes('consultSlider'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Митне оформлення вантажів'
        limit={10}
        path='movf'
        existingPhotos={photos.filter((p) => p.includes('mov'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Митний склад'
        limit={10}
        path='msf'
        existingPhotos={photos.filter((p) => p.includes('msf'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Оренда складських прміщень'
        limit={10}
        path='ospf'
        existingPhotos={photos.filter((p) => p.includes('ospf'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Оренда офісних приміщень'
        limit={10}
        path='oopf'
        existingPhotos={photos.filter((p) => p.includes('oopf'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Міжнародні та внутрішні перевезення'
        limit={10}
        path='mtvpf'
        existingPhotos={photos.filter((p) => p.includes('mtvpf'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Оренда техніки'
        limit={10}
        path='otf'
        existingPhotos={photos.filter((p) => p.includes('otf'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Митно-брокерські послуги'
        limit={10}
        path='mbpf'
        existingPhotos={photos.filter((p) => p.includes('mbpf'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Конференц зали'
        limit={10}
        path='kzf'
        existingPhotos={photos.filter((p) => p.includes('kzf'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Автосервіс'
        limit={10}
        path='avsf'
        existingPhotos={photos.filter((p) => p.includes('avsf'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Доставка персоналу'
        limit={10}
        path='dpf'
        existingPhotos={photos.filter((p) => p.includes('dpf'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Комплексне харчування'
        limit={10}
        path='khf'
        existingPhotos={photos.filter((p) => p.includes('khf'))}
        onSave={fetchPhotos}
      />
      <PhotoUpload
        name='Документи'
        limit={10}
        path='docs'
        existingPhotos={photos.filter((p) => p.includes('docs'))}
        onSave={fetchPhotos}
      />
    </div>
  );
};

export default PhotoEditorScreen;
