import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../bootstrap/css/bootstrap.min.css";
const AdminLogin: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [response, setResponse] = useState<string>("");
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL || "zahid-resource.com";


  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        const res = await axios.post(`${serverUrl}/login`, {
          username,
          password,
        });
        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          navigate("/admin/dashboard");
        }
      } catch (err) {
        setResponse("Invalid credentials");
      }
    },
    [navigate, password, username, setResponse, serverUrl]
  );
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/admin/dashboard");
    }
  }, [navigate, serverUrl]);

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="w-100" style={{ maxWidth: 600 }}>
        <h1 className="text-center mb-4">Admin Login</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100 mb-2">
            Login
          </button>
        </form>
        {response && <p className="mt-3 text-center">{response}</p>}
      </div>
    </div>
  );
};

export default AdminLogin;
