import React, { useCallback } from 'react';

type SInputProps = {
  value: string;
  onChange: any;
  placeholder?: string;
  style?: React.CSSProperties;
  id: string;
};

const StyledInput = (props: SInputProps) => {
  const { value, onChange, placeholder, style, id } = props;

  const changeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <input
      id={id}
      type='text'
      value={value}
      onChange={changeCallback}
      placeholder={placeholder}
      style={{
        borderWidth: 0,
        backgroundColor: '#EAEAEA',
        color: '#0D1C45',
        fontSize: 14,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 15,
        height: 45,
        lineHeight: '16.8px',
        outline: 'none', // Забезпечує відсутність бордера при фокусі
        ...style,
      }}
    />
  );
};

export default StyledInput;
