import React, { useMemo, useState } from 'react';
import { Card, Modal, Container, Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Arrow from '../assets/arrow.svg';
import MOV_IC from '../assets/mov.webp';
import MS_IC from '../assets/ms.webp';
import OSP_IC from '../assets/osp.webp';
import OOP_IC from '../assets/oop.webp';
import MTVP_IC from '../assets/mtvp.webp';
import OVRT_IC from '../assets/ovrt.webp';
import AVT_IC from '../assets/avtoservis.webp';
import DP_IC from '../assets/dp.webp';
import CONF_IC from '../assets/conf-zal.png';
import MBP_IC from '../assets/mbp.png';
import HAR_IC from '../assets/harch.webp';
import '../DesriptionBlock/style.css';
import { useTranslation } from '../../i18n/translator';

import ImageSlider from '../animations/ImageSlider';

import Close from '../assets/close.png';
import { stripHTMLTags } from '../../../src/helpers';
import { usePhotos } from '../PhotoEditor/PhotoProvider';

interface Service {
  id: number;
  title: string;
  description: string;
  images: string[];
  icon: string;
  modalHeader: string;
}

const Services = () => {
  const { photos } = usePhotos();
  const services: Service[] = useMemo(
    () => [
      {
        id: 1,
        title: 'customs_clearance',
        description: `customs_clearance_description`,
        images: photos.filter((p) => p.includes('movf')),
        icon: MOV_IC,
        modalHeader: 'назва',
      },
      {
        id: 2,
        title: 'customs_warehouse',
        description: 'customs_warehouse_description',
        images: photos.filter((p) => p.includes('msf')),
        icon: MS_IC,
        modalHeader: 'назва',
      },
      {
        id: 3,
        title: 'warehouse_lease',
        description: 'warehouse_lease_description',
        images: photos.filter((p) => p.includes('ospf')),
        icon: OSP_IC,
        modalHeader: 'назва',
      },

      {
        id: 4,
        title: 'office_lease',
        description: 'office_lease_description',
        images: photos.filter((p) => p.includes('oopf')),
        icon: OOP_IC,
        modalHeader: 'назва',
      },
      {
        id: 5,
        title: 'transportation',
        description: 'transportation_description',
        images: photos.filter((p) => p.includes('mtvpf')),
        icon: MTVP_IC,
        modalHeader: 'назва',
      },
      {
        id: 6,
        title: 'equipment_rental',
        description: 'equipment_rental_description',
        images: photos.filter((p) => p.includes('otf')),
        icon: OVRT_IC,
        modalHeader: 'назва',
      },
      {
        id: 7,
        title: 'customs_brokerage_services',
        description: 'customs_brokerage_services_description',
        images: photos.filter((p) => p.includes('mbpf')),
        icon: MBP_IC,
        modalHeader: 'назва',
      },
      {
        id: 8,
        title: 'conference_halls',
        description: 'conference_halls_description',
        images: photos.filter((p) => p.includes('kzf')),
        icon: CONF_IC,
        modalHeader: 'назва',
      },
      {
        id: 9,
        title: 'car_service',
        description: 'car_service_description',
        images: photos.filter((p) => p.includes('avsf')),
        icon: AVT_IC,
        modalHeader: 'назва',
      },
      {
        id: 10,
        title: 'shuttle_service',
        description: 'shuttle_service_description',
        images: photos.filter((p) => p.includes('dpf')),
        icon: DP_IC,
        modalHeader: 'назва',
      },
      {
        id: 11,
        title: 'catering',
        description: 'catering_description',
        images: photos.filter((p) => p.includes('hf')),
        icon: HAR_IC,
        modalHeader: 'назва',
      },
    ],
    [photos]
  );

  const { t } = useTranslation();
  const [selectedService, setSelectedService] = useState<Service | null>(null);

  const handleCardClick = (service: Service) => {
    setSelectedService(service);
  };

  const handleClose = () => {
    setSelectedService(null);
  };

  return (
    <Container id='services'>
      <div style={{ overflow: 'hidden' }}>
        <h1
          style={{
            textAlign: 'center',
            marginTop: 30,
            marginBottom: 10,
          }}
          className='slide-up animate'
        >
          {t('translate:we_ples')}
        </h1>
      </div>
      <div style={{ overflow: 'hidden' }}>
        <h2
          style={{
            textAlign: 'center',
            fontWeight: 400,
            maxWidth: 530,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 30,
            marginBottom: 60,
          }}
          className='slide-up animate '
        >
          {t('translate:we_offer')}
        </h2>
      </div>
      <Row style={{ justifyContent: 'center', flexWrap: 'wrap' }}>
        {services.map((service) => (
          <Col
            key={service.id}
            md={6}
            sm={12}
            lg={4}
            className='mb-4 slide-up animate'
          >
            <Card
              onClick={() => handleCardClick(service)}
              style={{
                height: 213,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.20)',
                cursor: 'pointer',
                position: 'relative',
                borderRadius: 12,
                borderWidth: 0,
              }}
            >
              <Card.Body>
                <img width={52} src={service.icon} alt={service.title} />
                <Card.Title
                  style={{
                    fontSize: 24,
                    lineHeight: '29.05px',
                    fontWeight: '500',
                  }}
                >
                  {t(`translate:${service.title}`)}
                </Card.Title>
                <Card.Text
                  style={{
                    position: 'absolute',
                    bottom: 15,
                    right: 20,
                    fontSize: 14,
                    lineHeight: '16.8px',
                    cursor: 'pointer',
                  }}
                >
                  {stripHTMLTags(t('translate:more', { onlyText: true }))}
                  <img src={Arrow} alt={t('translate:more').toString()} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal show={!!selectedService} onHide={handleClose} centered size='xl'>
        <Image
          style={{
            position: 'absolute',
            top: 15,
            right: 15,
            cursor: 'pointer',
            fontSize: 30,
            borderRadius: 100,
            height: 15,
            width: 15,
            textAlign: 'center',
          }}
          onClick={handleClose}
          src={Close}
          alt='close modal'
        />
        {selectedService && (
          <div
            style={{
              padding: '5vw',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h1 style={{ fontSize: 24, fontWeight: 600, marginBottom: 30 }}>
              {t(`translate:${selectedService.title}`)}
            </h1>

            <h2 style={{ fontSize: 18, fontWeight: '500', marginBottom: 30 }}>
              {t(`translate:${selectedService.description}`)}
            </h2>
            <ImageSlider images={selectedService.images} maxWidth='100%' />
          </div>
        )}
      </Modal>
    </Container>
  );
};

export default Services;
