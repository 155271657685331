import React from 'react';
import { stripHTMLTags } from '../../../src/helpers';

type SButtonProps = {
  action: (e?: any) => Promise<void> | void;
  text: any;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
};

const StyledButton = (props: SButtonProps) => {
  const { action, text, style, children = <></>, type } = props;
  return (
    <button
      onClick={action}
      style={{
        borderWidth: 0,
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '#0D1C45',
        color: 'white',
        fontSize: 14,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 15,
        height: 45,
        lineHeight: '16.8px',
        ...style,
      }}
      type={type}
    >
      {stripHTMLTags(text.toString()).toUpperCase()}
      {children}
    </button>
  );
};

export default StyledButton;
