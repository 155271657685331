import React, { useState, useEffect } from "react";
import "./styles.css";

interface ImageSliderProps {
  images: string[];
  interval?: number;
  maxWidth?: string;
  maxHeight?: string;
}

const ImageSlider: React.FC<ImageSliderProps> = ({
  images,
  interval = 3000,
  maxWidth = "80vw",
  maxHeight = "50vw",
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fadeOut = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const slideInterval = window.setInterval(fadeOut, interval);

    return () => {
      clearInterval(slideInterval);
    };
  }, [images.length, interval]);

  return (
    <div
      className="image-slider-container"
      style={{
        maxWidth: maxWidth,
        maxHeight: maxHeight,
      }}
    >
      <div className="image-slider">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            className={`slide-image ${index === currentIndex ? "active" : ""}`}
            alt={"митниці"}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
