import React from "react";
import "./animations/styles.css";
type LineProps = {
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
  width?: any;
  height?: any;
  className?: string;
};

const Line = ({
  top = 0,
  left = 0,
  right = 0,
  bottom = 0,
  width = 1,
  height = 1,
  className = "",
}: LineProps) => {
  const getPixelValue = (value: string) => {
    const element = document.createElement("div");
    element.style.position = "absolute";
    element.style.visibility = "hidden";
    element.style.width = value;
    document.body.appendChild(element);
    const pixels = window.getComputedStyle(element).width;
    document.body.removeChild(element);
    return parseFloat(pixels);
  };

  const widthInPixels = getPixelValue(width);
  const heightInPixels = getPixelValue(height);

  const expandClass =
    widthInPixels > heightInPixels ? "scale-size-x" : "scale-size-y";
  return (
    <div
      style={{
        position: "absolute",
        top: top,
        left: left,
        right: right,
        bottom: bottom,
        width: width,
        height: height,
        backgroundColor: "#ccc",
        zIndex: 0,
      }}
      className={`${expandClass} animate ${className}`}
    />
  );
};

export default Line;
