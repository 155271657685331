import React, { useState, useEffect, useRef } from "react";
import { Slide } from "./Styled";

type Props = {
  animationStarted: boolean;
};

const ElementSlideAnimation = ({ animationStarted = false }: Props) => {
  const [reverseAnimation, setReverseAnimation] = useState(false);
  const prevAnimation = useRef(animationStarted);

  useEffect(() => {
    if (!animationStarted && prevAnimation.current) {
      prevAnimation.current = false;

      setReverseAnimation(true);
      setTimeout(() => {
        setReverseAnimation(false);
      }, 1200);
    } else {
      prevAnimation.current = animationStarted;

      setReverseAnimation(false);
    }
  }, [animationStarted]);

  if (!animationStarted && !reverseAnimation) return null;
  return <Slide reverse={reverseAnimation ? "true" : "false"} />;
};

export default ElementSlideAnimation;
