import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import '../../bootstrap/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import iconUrl from '../assets/logistics.webp';
import '../DesriptionBlock/style.css';
import Line from '../line';
import { useTranslation } from '../../i18n/translator';
import '../Advantages/styles.css';
const customIcon = new L.Icon({
  iconUrl: iconUrl,
  iconSize: [41, 41],
  iconAnchor: [20.5, 41],
  popupAnchor: [1, -34],
});

const MapBlock = () => {
  const { t } = useTranslation();
  const [h1Animated, setH1Animated] = useState(false);
  const h1Ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const h1Element = h1Ref.current;
    if (h1Element) {
      h1Element.addEventListener('animationend', handleAnimationEnd);
    }
    return () => {
      if (h1Element) {
        h1Element.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, [h1Ref]);

  const handleAnimationEnd = () => {
    setTimeout(() => {
      setH1Animated(true);
    }, 1000);
  };

  const renderLastLines = useCallback(() => {
    if (h1Animated) {
      return (
        <>
          <Line
            width={75}
            height={1}
            left={-35}
            top={'58%'}
            className='animated'
          />
          <Line
            width={60}
            height={1}
            left={-35}
            top={-5}
            className='animated'
          />
        </>
      );
    }
    return null;
  }, [h1Animated]);

  return (
    <div
      id='location'
      className='container py-5 '
      style={{
        maxWidth: 1500,
        paddingLeft: 0,
        paddingRight: 0,
        gap: 20,
        justifyContent: 'space-evenly',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div className='row justify-content-center '>
        <div
          className='col-12 col-lg-6 mb-4 mb-lg-0 d-flex justify-content-center align-items-center'
          style={{ flexDirection: 'column', maxWidth: '90vw' }}
        >
          <h1
            ref={h1Ref}
            className='big-text slide-left animate'
            style={{
              fontWeight: '600',
              width: 500,
              maxWidth: '80vw',
              textAlign: 'left',
              lineHeight: '62.4px',
            }}
          >
            {t('translate:our_location')}
          </h1>
          <h2
            className='text slide-left animate'
            style={{
              marginTop: 20,
              textAlign: 'left',
              color: '#333333',
              maxWidth: 500,
              lineHeight: '28.8px',
            }}
          >
            {t('translate:location_1')}
          </h2>
          <h2
            className='text slide-left animate'
            style={{
              marginTop: 20,
              textAlign: 'left',
              color: '#333333',
              maxWidth: 500,
              lineHeight: '28.8px',
            }}
          >
            {t('translate:customs_department_1')}
          </h2>
          <h2
            className='text slide-left animate'
            style={{
              marginTop: 20,
              textAlign: 'left',
              color: '#333333',
              maxWidth: 500,
              lineHeight: '28.8px',
            }}
          >
            {t('translate:customs_department_2')}
          </h2>
          <h2
            className='text slide-left animate'
            style={{
              marginTop: 20,
              textAlign: 'left',
              color: '#333333',
              maxWidth: 500,
              lineHeight: '28.8px',
            }}
          >
            {t('translate:customs_department_3')}
          </h2>
        </div>
        <div
          className='col-12 col-lg-6 mb-4 mb-lg-0 d-flex justify-content-center align-items-center animate slide-right'
          style={{ flex: 1, position: 'relative', maxWidth: '99vw' }}
        >
          <Line height={'120%'} right={'99%'} top={'-10%'} />
          {renderLastLines()}
          <div
            style={{
              borderRadius: 30,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <MapContainer
              center={[49.79221, 23.713463]}
              zoom={12}
              className='map-container'
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
              <Marker position={[49.79221, 23.713463]} icon={customIcon}>
                <Popup>Митний пост «Городок». ВМО №1.</Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapBlock;
