import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const submitForm = async ({
  name,
  contact,
  message,
  option,
}: {
  name?: string;
  contact: string;
  message?: string;
  option?: string;
}) => {
  const submitName = name || 'Не вказано';
  const submitMessage = message || 'Без повідомлення';
  const submitOption = option || 'Не обрано';

  try {
    const response = await axios.post(`${serverUrl}/feedback`, {
      name: submitName,
      contact,
      message: submitMessage,
      selectedOption: submitOption,
    });

    if (response.status === 201) {
      const el = document.getElementById('success_modal');

      if (el) {
        el.style.visibility = 'visible';
        el.style.opacity = '1';
      }
      return { message: 'Feedback submitted successfully', success: true };
    } else {
      return { message: 'Feedback failed to submit', success: false };
    }
  } catch (error) {
    console.error('Error submitting feedback:', error);
    return { message: 'Feedback failed to submit', success: false };
  }
};
