import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from 'react';

// Тип для зображень

// Типи для контексту
interface PhotoContextType {
  photos: string[];
  loading: boolean;
  error: string | null;
  fetchPhotos: () => Promise<void>;
}

// Створюємо контекст з початковим значенням
const PhotoContext = createContext<PhotoContextType | undefined>(undefined);

// Провайдер для фото
interface PhotoProviderProps {
  children: ReactNode;
}

export const PhotoProvider: React.FC<PhotoProviderProps> = ({ children }) => {
  const [photos, setPhotos] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Функція для отримання фото з сервера
  const fetchPhotos = async () => {
    setLoading(true); // Встановлюємо статус завантаження
    try {
      const response = await fetch(`https://zahid-resource.com/Photos`);
      if (!response.ok) {
        throw new Error('Failed to fetch photos');
      }
      const res: string[] = await response.json();
      setPhotos(res); // Зберігаємо зображення в стан
      setLoading(false); // Завантаження завершено
    } catch (error) {
      setError((error as Error).message); // Записуємо помилку
      setLoading(false); // Завантаження завершено
    }
  };

  // Викликаємо fetchPhotos при першому завантаженні компонента
  useEffect(() => {
    fetchPhotos();
  }, []);

  return (
    <PhotoContext.Provider value={{ photos, loading, error, fetchPhotos }}>
      {children}
    </PhotoContext.Provider>
  );
};

// Хук для доступу до фото
export const usePhotos = (): PhotoContextType => {
  const context = useContext(PhotoContext);
  if (!context) {
    throw new Error('usePhotos must be used within a PhotoProvider');
  }
  return context;
};
