import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import MainScreen from './components/MainScreen';
import TranslationEditor from './components/TextEditorScreen';
import PhotoEditorScreen from './components/PhotoEditor/screen';
import { PhotoProvider } from './components/PhotoEditor/PhotoProvider';

const App: React.FC = () => {
  return (
    <PhotoProvider>
      <Router>
        <Routes>
          <Route path='/' element={<MainScreen />} />
          <Route path='/admin' element={<AdminLogin />} />
          <Route path='/admin/dashboard' element={<AdminDashboard />} />
          <Route path='/admin/textEditor' element={<TranslationEditor />} />
          <Route path='/admin/photoEditor' element={<PhotoEditorScreen />} />
        </Routes>
      </Router>
    </PhotoProvider>
  );
};

export default App;
